import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Form, Modal, message, Input, Image, Select } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import AssessmentsForm from './AssessmentsForm'
import { useLoading } from '../../../context/useLoading'
import { ASSESSMENT_T, ASSESSMENT_T_LC } from './enum'
import { ASSESSMENT_TABLES } from './tables'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Assessments = ({
  assessments,
  createHandler,
  updateHandler,
  deleteHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [assessmentsItem, setAssessmentsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchItem, setSearchItem] = useState(null)
  const [listAssessments, setListAssessments] = useState(null)
  const [type, setType] = useState(ASSESSMENT_T.chooseCorrectPicture)
  const [columns, setColumns] = useState()
  const { hideLoading, showLoading } = useLoading()

  useEffect(() => {
    const filteredAssessments = assessments.filter(assessment => {
      const matchesType = assessment.type === type
      const matchesSearch = searchItem
        ? assessment.correctText
            ?.toLowerCase()
            .includes(searchItem.toLowerCase())
        : true
      return matchesType && matchesSearch
    })

    setListAssessments(filteredAssessments)
  }, [assessments, searchItem, type])

  const actionColumn = {
    title: 'Действие',
    width: '20%',
    render: item => (
      <>
        <Button
          type="link"
          onClick={() => {
            formForEdit.resetFields()
            setEditModal(true)
            setAssessmentsItem(item)
          }}
        >
          Редактировать
        </Button>
        <Button
          danger
          type="link"
          onClick={() => {
            setDeleteModal(true)
            setAssessmentsItem(item)
          }}
        >
          Удалить
        </Button>
      </>
    )
  }

  useEffect(() => {
    const table = [...ASSESSMENT_TABLES[type]]
    table.push(actionColumn)
    setColumns(table)
    // hideLoading()
  }, [type])

  return (
    <>
      <Table
        dataSource={listAssessments}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Текст"
              onChange={e => {
                setSearchItem(e.target.value.toLowerCase())
              }}
            />
            <div style={{ display: 'flex', columnGap: 50 }}>
              <Select
                style={{ width: 250 }}
                placeholder="Выберите тип"
                value={type}
                onChange={val => {
                  setType(val)
                  // showLoading()
                }}
              >
                {Object.keys(ASSESSMENT_T).map(field => (
                  <Select.Option
                    key={ASSESSMENT_T[field]}
                    value={ASSESSMENT_T[field]}
                  >
                    {ASSESSMENT_T_LC[field]}
                  </Select.Option>
                ))}
              </Select>
              <Button type="primary" onClick={() => setModalVisible(true)}>
                <PlusOutlined /> Новая задача
              </Button>
            </div>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая задача"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <AssessmentsForm form={form} />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
          }}
          onOk={() => {
            updateHandler(assessmentsItem._id, formForEdit, setEditModal)
          }}
        >
          <AssessmentsForm form={formForEdit} initialValues={assessmentsItem} />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(assessmentsItem._id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить {assessmentsItem.correctText} ?
          </h3>
        </Modal>
      )}
    </>
  )
}

Assessments.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Assessments
