import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../../../context/useLoading'
import WithMainLayout from '../../../../hocs/withMainLayout'
import Loading from '../../../shared/Loading'
import {
  axiosKazlingoFormData,
  axiosKazlingoJson
} from '../../../../utils/axiosKazlingo'
import Sections from './Sections'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const SectionsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  // const location = useLocation()
  const { moduleId } = useParams()
  // const { sections = null } = location.state || {}
  const [allSections, setAllSections] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const { data } = await axiosKazlingoJson.get(
          `/roadmaps/${moduleId}/sections`
        )
        setAllSections(data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    // if (!sections) {
    fetchSections()
    // } else {
    // setAllSections(sections)
    // setLoading(false)
    // }
  }, [])

  const addSectionHandler = async (form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      Object.keys(values).forEach(key => {
        formData.append(key, values[key])
      })
      const response = await axiosKazlingoFormData.post(
        `/roadmaps/${moduleId}/sections`,
        formData
      )
      if (response.status == 200) {
        setAllSections([...allSections, values])
        toast.success('Успешно добавлено')
      }
    } catch (error) {
      console.error('Ошибка при добавлении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const updateSectionHandler = async (_id, form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      Object.keys(values).forEach(key => {
        formData.append(key, values[key])
      })
      const response = await axiosKazlingoFormData.put(
        `/roadmaps/${moduleId}/sections/${_id}`,
        formData
      )
      values._id = _id
      if (response.status == 200) {
        setAllSections(allSections.map(a => (a._id === _id ? values : a)))
        toast.success('Успешно обновлено')
      }
    } catch (error) {
      console.error('Ошибка при обновлении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteSectionHandler = async (_id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoJson.delete(`/roadmaps/${moduleId}/sections/${_id}`)
      setAllSections(allSections.filter(a => a._id !== _id))
      toast.success('Успешно удалено')
    } catch (error) {
      console.error('Ошибка при удалении:', error)
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors[0].error_message
          : 'Ошибка при добавлении'
      toast.error(errorMessage)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке</div>
  }

  return (
    <Sections
      sections={allSections}
      createHandler={addSectionHandler}
      updateHandler={updateSectionHandler}
      deleteHandler={deleteSectionHandler}
    />
  )
}

export default WithMainLayout(SectionsContainer)
