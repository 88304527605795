import React, { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import RiddlesCollections from './RiddlesCollections'

const GET_RIDDLES_COLLECTIONS = gql`
  query statusedRiddlesCollections {
    statusedRiddlesCollections {
      id
      titleRus
      titleKaz
      verticalImageUrl
      horizontalImageUrl
      status
      riddles {
        id
        riddleText
        answerText
      }
    }
  }
`
const CREATE_RIDDLES_COLLECTION = gql`
  mutation createRiddlesCollection($input: RiddlesCollectionsInput) {
    createRiddlesCollection(input: $input) {
      id
      titleRus
      titleKaz
      verticalImageUrl
      horizontalImageUrl
      status
      riddles {
        id
        riddleText
        answerText
      }
    }
  }
`
const UPDATE_RIDDLES_COLLECTION = gql`
  mutation updateRiddlesCollection($input: UpdateRiddlesCollectionsInput) {
    updateRiddlesCollection(input: $input) {
      id
      titleRus
      titleKaz
      verticalImageUrl
      horizontalImageUrl
      status
      riddles {
        id
        riddleText
        answerText
      }
    }
  }
`

const DELETE_RIDDLES_COLLECTION = gql`
  mutation deleteRiddlesCollection($id: ID!) {
    deleteRiddlesCollection(id: $id) {
      id
    }
  }
`

const GET_RIDDLES = gql`
  query riddles {
    riddles {
      id
      riddleText
      answerText
    }
  }
`

const RiddlesContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [riddlesCollections, setRiddlesCollections] = useState([])
  const [riddles, setRiddles] = useState([])

  const { loading: riddlesCollectionsLoading } = useQuery(
    GET_RIDDLES_COLLECTIONS,
    {
      onCompleted: data => {
        setRiddlesCollections(data.statusedRiddlesCollections)
      },
      onError: error => console.log(error)
    }
  )

  const { loading: riddlesLoading } = useQuery(GET_RIDDLES, {
    onCompleted: data => {
      setRiddles(data.riddles)
    },
    onError: error => console.log(error)
  })

  const [createRiddlesCollection] = useMutation(CREATE_RIDDLES_COLLECTION, {
    onError: error => console.log(error),
    update(cache, { data: { createRiddlesCollection: newItem } }) {
      const { statusedRiddlesCollections } = cache.readQuery({
        query: GET_RIDDLES_COLLECTIONS
      })
      const newArray = statusedRiddlesCollections
        .filter(item => item.id !== newItem.id)
        .concat(newItem)
      setRiddlesCollections(newArray)
      cache.writeQuery({
        query: GET_RIDDLES_COLLECTIONS,
        data: {
          statusedRiddlesCollections: newArray
        }
      })
      hideLoading()
      toast.success('Коллекция загадок добавлена')
    }
  })

  const [updateRiddlesCollection] = useMutation(UPDATE_RIDDLES_COLLECTION, {
    onError: error => console.log(error),
    update(cache, { data: { updateRiddlesCollection: newItem } }) {
      const { statusedRiddlesCollections } = cache.readQuery({
        query: GET_RIDDLES_COLLECTIONS
      })
      const newArray = statusedRiddlesCollections.map(item =>
        item.id !== newItem.id ? item : newItem
      )
      setRiddlesCollections(newArray)
      cache.writeQuery({
        query: GET_RIDDLES_COLLECTIONS,
        data: {
          statusedRiddlesCollections: newArray
        }
      })
      hideLoading()
      toast.success('Коллекция загадок обновлена')
    }
  })

  const [deleteRiddlesCollection] = useMutation(DELETE_RIDDLES_COLLECTION, {
    onError: error => console.log(error),
    update(cache, { data: { deleteRiddlesCollection: newItem } }) {
      const { statusedRiddlesCollections } = cache.readQuery({
        query: GET_RIDDLES_COLLECTIONS
      })
      const newArray = statusedRiddlesCollections.filter(
        item => item.id !== newItem.id
      )
      setRiddlesCollections(newArray)
      cache.writeQuery({
        query: GET_RIDDLES_COLLECTIONS,
        data: {
          statusedRiddlesCollections: newArray
        }
      })
      hideLoading()
      toast.success('Коллекция загадок удалена')
    }
  })

  const createHandler = (form, closeFunc) => {
    form
      .validateFields()
      .then(val => {
        form.resetFields()
        showLoading()
        createRiddlesCollection({
          variables: {
            input: {
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              ...(val.verticalImageFile && {
                verticalImageFile: val.verticalImageFile
              }),
              ...(val.horizontalImageFile && {
                horizontalImageFile: val.horizontalImageFile
              }),
              status: val.status,
              riddles: val.riddles
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const updateHandler = (form, id, closeFunc) => {
    showLoading()
    form
      .validateFields()
      .then(val => {
        updateRiddlesCollection({
          variables: {
            input: {
              id,
              titleRus: val.titleRus,
              titleKaz: val.titleKaz,
              ...(val.verticalImageFile && {
                verticalImageFile: val.verticalImageFile
              }),
              ...(val.horizontalImageFile && {
                horizontalImageFile: val.horizontalImageFile
              }),
              status: val.status,
              riddles: val.riddles
            }
          }
        })
      })
      .then(() => {
        closeFunc(false)
      })
  }

  const deleteHandler = (id, closeFunc) => {
    showLoading()
    deleteRiddlesCollection({
      variables: { id }
    }).then(() => {
      hideLoading()
      closeFunc(false)
    })
  }

  if (riddlesCollectionsLoading || riddlesLoading) {
    return <Loading />
  }

  return (
    <RiddlesCollections
      riddlesCollections={riddlesCollections}
      createHandler={createHandler}
      updateHandler={updateHandler}
      deleteHandler={deleteHandler}
      riddles={riddles}
    />
  )
}

export default WithMainLayout(RiddlesContainer)
