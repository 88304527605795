import React from 'react'
import { Form, Input, Button, Upload, Radio, Image, Typography } from 'antd'
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'

const ChooseCorrectPictureVariants = ({ form }) => (
  <Form.List name="variants" initialValue={[]}>
    {(fields, { add, remove }) => (
      <>
        {fields.map((field, index) => (
          <Form.Item key={field.key} label={`Вариант ${index + 1}`} required>
            <Form.Item
              name={[field.name, 'text']}
              rules={[{ required: true, message: 'Пожалуйста, введите текст' }]}
            >
              <Input placeholder="Введите текст" />
            </Form.Item>
            <Form.Item
              name={[field.name, 'isCorrect']}
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, выберите правильный вариант'
                }
              ]}
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={true}>Правильный</Radio>
                <Radio value={false}>Не правильный</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={[field.name, 'imageFile']}
              valuePropName="file"
              getValueFromEvent={e => e.file}
              rules={[
                { required: true, message: 'Пожалуйста, загрузите изображение' }
              ]}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Выберите изображение</Button>
              </Upload>
            </Form.Item>
            {!form.getFieldValue(['variants', field.key, 'imageFile']) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <Typography.Text>
                  Внимание, находящееся фото ниже это то, что сохранено в базе,
                  чтобы увидеть выбранное надо сохранить.
                </Typography.Text>
                <Image
                  shape="square"
                  style={{ width: 150, height: 150 }}
                  src={form.getFieldValue(['variants', field.key, 'imageUrl'])}
                />
              </div>
            )}
            <Button
              type="dashed"
              onClick={() => remove(field.name)}
              style={{ marginTop: '10px' }}
            >
              Удалить вариант
            </Button>
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="dashed" onClick={() => add()} style={{ width: '60%' }}>
            <PlusOutlined /> Добавить вариант
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
)

export default ChooseCorrectPictureVariants
