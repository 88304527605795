import React, { useState } from 'react'
import { Table, Button, Form, Modal, Input, message, Avatar, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import PropTypes from 'prop-types'
import EducationElementForm from './EducationElementForm'
const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const EducationElements = ({
  topic,
  educationElements,
  loading,
  addEducationElementHandler,
  updateEducationElementHandler,
  deleteEducationElementHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [educationElementItem, setEducationElementItem] = React.useState(null)
  const [editModal, setEditModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [searchEducationElement, setSearchEducationElement] = React.useState()
  const [listEducationElements, setListEducationElements] = React.useState(null)

  const [audioFile, setAudioFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)

  const [practiceAudioFile, setPracticeAudioFile] = useState(null)
  const [practiceImageFile, setPracticeImageFile] = useState(null)

  const audioFileRef = React.useRef()
  const imageFileRef = React.useRef()

  const practiceAudioFileRef = React.useRef()
  const practiceImageFileRef = React.useRef()

  React.useEffect(() => {
    const descriptiveEducationElements = educationElements.map(
      educationElement => {
        return {
          id: educationElement.id,
          status: educationElement.status,
          index: educationElement.index,
          name: educationElement.name,
          value: educationElement.value,
          audioURL: educationElement.audioURL,
          imageURL: educationElement.imageURL,
          practiceAudioURL: educationElement.practiceAudioURL,
          practiceImageURL: educationElement.practiceImageURL
        }
      }
    )
    let searchArray = descriptiveEducationElements

    if (searchEducationElement) {
      searchArray = searchArray.filter(educationElement => {
        return educationElement.educationElement
          ?.toLowerCase()
          .includes(searchEducationElement)
      })
    }

    setListEducationElements(searchArray)
  }, [educationElements, searchEducationElement])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: '#',
      width: '1%',
      dataIndex: 'index'
    },
    {
      title: 'Фото',
      width: '5%',
      render: item => {
        if (item.imageURL) {
          return (
            <>
              <Avatar shape="square" size={64} src={item.imageURL} />
            </>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Аудио',
      width: '5%',
      render: item => {
        if (item.audioURL) {
          let audioLink = item.audioURL
          if (!audioLink.startsWith('https://')) {
            audioLink = 'https://' + audioLink
          }
          return (
            <audio controls="controls">
              <source src={audioLink} type="audio/mpeg" />
            </audio>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Фото(практика)',
      width: '5%',
      render: item => {
        if (item.practiceImageURL) {
          return (
            <>
              <Avatar shape="square" size={64} src={item.practiceImageURL} />
            </>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Аудио(практика)',
      width: '5%',
      render: item => {
        if (item.practiceAudioURL) {
          let audioLink = item.practiceAudioURL
          if (!audioLink.startsWith('https://')) {
            audioLink = 'https://' + audioLink
          }
          return (
            <audio controls="controls">
              <source src={audioLink} type="audio/mpeg" />
            </audio>
          )
        } else {
          return <>-</>
        }
      }
    },
    {
      title: 'Слово',
      dataIndex: 'name',
      width: '5%'
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      width: '5%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <span>
            <Button
              type="link"
              onClick={() => {
                formForEdit.resetFields()
                setEditModal(true)
                setEducationElementItem(item)
              }}
            >
              Редактировать
            </Button>
            <Button
              danger
              type="link"
              onClick={() => {
                setDeleteModal(true)
                setEducationElementItem(item)
              }}
            >
              Удалить
            </Button>
          </span>
        </>
      )
    }
  ]

  return (
    <>
      <Link to={'/topics'}>
        <Tag>Темы</Tag>
      </Link>
      <Tag>{'->'}</Tag>
      <Tag>Слова</Tag>
      <Table
        dataSource={listEducationElements}
        columns={columns}
        rowKey={item => item.id}
        loading={loading}
        pagination={{
          showSizeChanger: false
        }}
        title={() => (
          <>
            <Tag>
              {'Тема - ' + (topic?.textKZ || '') + ', ' + (topic?.textRU || '')}
            </Tag>
            <StyledHeaderContainer>
              <StyledInput
                prefix={<SearchOutlined />}
                placeholder="Слово"
                onChange={e => {
                  setSearchEducationElement(e.target.value.toLowerCase())
                }}
              />

              {topic && (
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  <PlusOutlined /> Новое слово
                </Button>
              )}
            </StyledHeaderContainer>
          </>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новое слово"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
          setAudioFile(null)
          setImageFile(null)
          setPracticeAudioFile(null)
          setPracticeImageFile(null)
          audioFileRef.current.value = null
          imageFileRef.current.value = null
          practiceAudioFileRef.current.value = null
          practiceImageFileRef.current.value = null
        }}
        onOk={() => {
          if (audioFile !== null) {
            if (imageFile !== null) {
              addEducationElementHandler(
                form,
                setModalVisible,
                audioFile,
                imageFile,
                practiceAudioFile,
                practiceImageFile,
                setAudioFile,
                setImageFile,
                setPracticeAudioFile,
                setPracticeImageFile,
                audioFileRef,
                imageFileRef,
                practiceAudioFileRef,
                practiceImageFileRef
              )
            } else {
              message.error('Пожалуйста, выберите Изображение')
            }
          } else {
            message.error('Пожалуйста, выберите Аудио файл')
          }
        }}
      >
        <EducationElementForm
          setAudioFile={setAudioFile}
          setImageFile={setImageFile}
          setPracticeAudioFile={setPracticeAudioFile}
          setPracticeImageFile={setPracticeImageFile}
          audioFileRef={audioFileRef}
          imageFileRef={imageFileRef}
          practiceAudioFileRef={practiceAudioFileRef}
          practiceImageFileRef={practiceImageFileRef}
          form={form}
        />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            setEditModal(false)
            setAudioFile(null)
            setImageFile(null)
            setPracticeAudioFile(null)
            setPracticeImageFile(null)
            audioFileRef.current.value = null
            imageFileRef.current.value = null
            practiceAudioFileRef.current.value = null
            practiceImageFileRef.current.value = null
          }}
          onOk={() => {
            updateEducationElementHandler(
              formForEdit,
              educationElementItem.id,
              setEditModal,
              audioFile,
              imageFile,
              practiceAudioFile,
              practiceImageFile,
              setAudioFile,
              setImageFile,
              setPracticeAudioFile,
              setPracticeImageFile,
              audioFileRef,
              imageFileRef,
              practiceAudioFileRef,
              practiceImageFileRef
            )
          }}
        >
          <EducationElementForm
            form={formForEdit}
            index={educationElementItem.index}
            status={educationElementItem.status}
            name={educationElementItem.name}
            value={educationElementItem.value}
            setAudioFile={setAudioFile}
            setImageFile={setImageFile}
            setPracticeAudioFile={setPracticeAudioFile}
            setPracticeImageFile={setPracticeImageFile}
            audioFileRef={audioFileRef}
            imageFileRef={imageFileRef}
            practiceAudioFileRef={practiceAudioFileRef}
            practiceImageFileRef={practiceImageFileRef}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() =>
            deleteEducationElementHandler(
              educationElementItem.id,
              setDeleteModal
            )
          }
        >
          <h3>
            Вы действительно хотите удалить '{educationElementItem.name}' ?
          </h3>
        </Modal>
      )}
    </>
  )
}

EducationElements.propTypes = {
  educationElements: PropTypes.arrayOf(PropTypes.object).isRequired,
  addEducationElementHandler: PropTypes.func.isRequired,
  updateEducationElementHandler: PropTypes.func.isRequired
}

export default EducationElements
