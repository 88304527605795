import React, { useEffect } from 'react'
import { Form, Input, Button, Upload, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  ChooseCorrectPictureVariants,
  ChooseCorrectTranslationVariants,
  WhatDidYouHearVariants,
  MatchWordsVariants,
  TranslateSentenceVariants,
  ListenAudioCompleteSentenceVariants,
  TranslateWordVariants,
  ListenAudioChooseWordVariants,
  FillBlankSpaceVariants
} from './forms'
import { ASSESSMENT_T, ASSESSMENT_T_LC } from './enum'

const AssessmentsForm = ({ form, initialValues }) => {
  const [stateType, setStateType] = React.useState(
    initialValues?.type || ASSESSMENT_T.chooseCorrectPicture
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const renderVariants = () => {
    switch (stateType) {
      case ASSESSMENT_T.chooseCorrectPicture:
        return <ChooseCorrectPictureVariants form={form} />
      case ASSESSMENT_T.chooseCorrectTranslation:
        return <ChooseCorrectTranslationVariants /> // same2 (one text)
      case ASSESSMENT_T.whatDidYouHear:
        return <WhatDidYouHearVariants form={form} /> // same1 (array of text)
      case ASSESSMENT_T.matchWords:
        return <MatchWordsVariants form={form} />
      case ASSESSMENT_T.translateSentence:
        return <TranslateSentenceVariants form={form} /> // same1 (array of text)
      case ASSESSMENT_T.translateWord:
        return <TranslateWordVariants /> // same2 (one text)
      case ASSESSMENT_T.listenAudioCompleteSentence:
        return <ListenAudioCompleteSentenceVariants form={form} /> // same1 (array of text)
      case ASSESSMENT_T.listenAudioChooseWord:
        return <ListenAudioChooseWordVariants /> // same2 (one text)
      case ASSESSMENT_T.fillBlankSpace:
        return <FillBlankSpaceVariants /> // same2 (one text)
      default:
        return null
    }
  }

  const renderFormItem = () => {
    switch (stateType) {
      case ASSESSMENT_T.chooseCorrectPicture:
      case ASSESSMENT_T.whatDidYouHear:
      case ASSESSMENT_T.translateSentence:
      case ASSESSMENT_T.translateWord:
      case ASSESSMENT_T.listenAudioCompleteSentence:
      case ASSESSMENT_T.listenAudioChooseWord:
        return (
          <>
            <Form.Item
              name="audioFile"
              label="Аудио файл"
              valuePropName="file"
              getValueFromEvent={e => e.file}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Выберите аудио файл</Button>
              </Upload>
            </Form.Item>
          </>
        )
      case ASSESSMENT_T.fillBlankSpace:
        return (
          <>
            <Form.Item
              name="audioFile"
              label="Аудио файл"
              valuePropName="file"
              getValueFromEvent={e => e.file}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Выберите аудио файл</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="imageFile"
              label="Фото файл"
              valuePropName="file"
              getValueFromEvent={e => e.file}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Выберите фото файл</Button>
              </Upload>
            </Form.Item>
          </>
        )
      case ASSESSMENT_T.chooseCorrectTranslation:
        return null // doesnt have any unusual
      case ASSESSMENT_T.matchWords:
        return null // in renderVariants

      default:
        return null
    }
  }

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        key="type"
        label="Тип"
        name="type"
        rules={[{ required: true, message: 'Пожалуйста, выберите тип' }]}
        initialValue={stateType}
      >
        <Select
          placeholder="Выберите тип"
          mode="single"
          onSelect={value => {
            setStateType(value)
          }}
        >
          {Object.keys(ASSESSMENT_T).map(field => (
            <Select.Option
              key={ASSESSMENT_T[field]}
              value={ASSESSMENT_T[field]}
            >
              {ASSESSMENT_T_LC[field]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {form.getFieldValue('type') !== ASSESSMENT_T.matchWords && (
        <Form.Item
          name="correctText"
          label="Название"
          rules={[{ required: true, message: 'Пожалуйста, введите название' }]}
        >
          <Input />
        </Form.Item>
      )}
      {renderFormItem()}
      {renderVariants()}
    </Form>
  )
}

export default AssessmentsForm
