import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Input, Table } from 'antd'
import moment from 'moment'
import WithMainLayout from '../../hocs/withMainLayout'
import Loading from '../shared/Loading'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const GET_TRANSACTIONS = gql`
  query transactionsByUsers($pagination: PaginationInput) {
    transactionsByUsers(pagination: $pagination) {
      transactions {
        id
        user {
          id
          name
          username
        }
        vendorTransactionId
        originalTransactionId
        transactionType
        plan
        planMonth
        expiresAt
        createdAt
      }
      total
      limit
      page
      pages
    }
  }
`
const columns = [
  {
    title: 'Имя',
    key: 'name',
    dataIndex: ['user', 'name'],
    width: '10%'
  },
  {
    title: 'Номер телефона/ почта',
    key: 'username',
    dataIndex: ['user', 'username'],
    width: '10%'
  },
  {
    title: 'План',
    key: 'plan',
    dataIndex: 'plan',
    width: '10%'
  },
  // {
  //   title: 'Месяц',
  //   key: 'planMonth',
  //   dataIndex: 'planMonth',
  //   width: '1%'
  // },
  {
    title: 'От',
    key: 'createdAt',
    dataIndex: 'createdAt',
    width: '10%'
  },
  {
    title: 'До',
    key: 'expiresAt',
    dataIndex: 'expiresAt',
    width: '10%'
  },
  {
    title: 'Откуда',
    key: 'transactionType',
    dataIndex: 'transactionType',
    width: '15%'
  },
  {
    title: 'Чек-1',
    key: 'vendorTransactionId',
    dataIndex: 'vendorTransactionId',
    width: '10%'
  },
  {
    title: 'Чек-2',
    key: 'originalTransactionId',
    dataIndex: 'originalTransactionId',
    width: '10%'
  }
]

const UsersTransactionsContainer = () => {
  const [transactions, setTransactions] = useState([])
  const [listItems, setListItems] = useState([])
  const [searchItem, setSearchItem] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [limit, setLimit] = React.useState(20)
  const [total, setTotal] = React.useState(1)
  React.useEffect(() => {
    if (searchItem.length) {
      const searchArray = transactions.filter(transaction => {
        return (
          transaction?.originalTransactionId
            .toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          transaction?.vendorTransactionId
            .toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          transaction?.user.username
            .toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          transaction?.user.name
            .toLowerCase()
            .includes(searchItem.toLowerCase()) ||
          transaction?.transactionType
            .toLowerCase()
            .includes(searchItem.toLowerCase())
        )
      })
      setListItems(searchArray)
    } else {
      console.log(transactions)
      setListItems(transactions)
    }
  }, [transactions, searchItem])

  const [getTransactions, { loading }] = useLazyQuery(GET_TRANSACTIONS, {
    variables: { pagination: { limit, page } },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const updatedTransactions = data.transactionsByUsers.transactions.map(
        transaction => {
          const expiresAt =
            transaction.expiresAt === 0
              ? moment()
              : moment(transaction.expiresAt)
          const formattedExpiresAt = expiresAt.format('DD.MM.YYYY')
          const createdAt =
            transaction.createdAt === 0
              ? moment()
              : moment(transaction.createdAt)
          const formattedCreatedAt = createdAt.format('DD.MM.YYYY')
          return {
            ...transaction,
            expiresAt: formattedExpiresAt,
            createdAt: formattedCreatedAt
          }
        }
      )

      setTransactions(updatedTransactions)
      setTotal(data.transactionsByUsers.total)
    },
    onError: error => {
      console.log(error)
    }
  })

  useEffect(() => {
    console.log('vizov')
    getTransactions()
  }, [])

  const handlePaginationChange = (page, limit) => {
    setPage(page)
    setLimit(limit)
    getTransactions({ variables: { pagination: { page, limit } } })
  }

  if (loading) return <Loading />

  return (
    <Table
      dataSource={listItems}
      rowKey={item => item.id}
      columns={columns}
      pagination={{
        current: page,
        pageSize: limit,
        total: total,
        defaultPageSize: 20,
        onChange: handlePaginationChange
      }}
      title={() => (
        <StyledHeaderContainer>
          <StyledInput
            prefix={<SearchOutlined />}
            placeholder="Номер телефона/Имя/e-mail пользователя"
            value={searchItem} // Set the value prop
            onChange={e => {
              setSearchItem(e.target.value.toLowerCase())
            }}
          />
        </StyledHeaderContainer>
      )}
    />
  )
}

export default WithMainLayout(UsersTransactionsContainer)
