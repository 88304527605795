import React from 'react'
import { Form, Input, Button } from 'antd'
import { PlusOutlined, RetweetOutlined } from '@ant-design/icons'

const shuffleArray = array => {
  return array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
}

const WhatDidYouHearVariants = ({ form }) => {
  const addVariantsFromCorrectText = () => {
    const correctText = form.getFieldValue('correctText')
    if (correctText) {
      const wordsArray = correctText.split(' ')
      const newVariants = wordsArray.map((word, index) => ({
        text: word,
        correctIndex: index
      }))
      form.setFieldsValue({
        variants: newVariants
      })
    }
  }

  const randomizeVariants = () => {
    const currentVariants = form.getFieldValue('variants') || []
    const shuffledVariants = shuffleArray(currentVariants)
    form.setFieldsValue({
      variants: shuffledVariants
    })
  }

  return (
    <Form.List name="variants" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item key={field.key} label={`Вариант ${index + 1}`} required>
              <Form.Item
                name={[field.name, 'text']}
                rules={[
                  { required: true, message: 'Пожалуйста, введите текст' }
                ]}
              >
                <Input placeholder="Введите текст" />
              </Form.Item>
              <Form.Item
                name={[field.name, 'correctIndex']}
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, выберите правильный индекс'
                  }
                ]}
                getValueFromEvent={e => Number(e.target.value)}
              >
                <Input type="number" placeholder="Введите правильный индекс" />
              </Form.Item>
              <Button
                type="dashed"
                onClick={() => remove(field.name)}
                style={{ marginTop: '10px' }}
              >
                Удалить вариант
              </Button>
            </Form.Item>
          ))}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button type="dashed" onClick={() => add()}>
              <PlusOutlined /> Добавить вариант
            </Button>
            <Button
              type="dashed"
              icon={<RetweetOutlined />}
              onClick={randomizeVariants}
            >
              Рандомизировать
            </Button>
            <Button type="dashed" onClick={addVariantsFromCorrectText}>
              Добавить варианты из correctText
            </Button>
          </div>
        </>
      )}
    </Form.List>
  )
}

export default WhatDidYouHearVariants
