import { ASSESSMENT_T } from '../enum'
import { chooseCorrectPictureTable } from './ChooseCorrectPicture'
import { chooseCorrectTranslationTable } from './ChooseCorrectTranslation'
import { fillBlankSpaceTable } from './FillBlankSpace'
import { listenAudioChooseWordTable } from './ListenAudioChooseWord'
import { listenAudioCompleteSentenceTable } from './ListenAudioCompleteSentence'
import { matchWordsTable } from './MatchWords'
import { translateSentenceTable } from './TranslateSentence'
import { translateWordTable } from './TranslateWord'
import { whatDidYouHearTable } from './WhatDidYouHear'

export const ASSESSMENT_TABLES = Object.freeze({
  [ASSESSMENT_T.chooseCorrectTranslation]: chooseCorrectTranslationTable,
  [ASSESSMENT_T.chooseCorrectPicture]: chooseCorrectPictureTable,
  [ASSESSMENT_T.whatDidYouHear]: whatDidYouHearTable,
  [ASSESSMENT_T.matchWords]: matchWordsTable,
  [ASSESSMENT_T.translateSentence]: translateSentenceTable,
  [ASSESSMENT_T.listenAudioCompleteSentence]: listenAudioCompleteSentenceTable,
  [ASSESSMENT_T.listenAudioChooseWord]: listenAudioChooseWordTable,
  [ASSESSMENT_T.translateWord]: translateWordTable,
  [ASSESSMENT_T.fillBlankSpace]: fillBlankSpaceTable
})
