import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLoading } from '../../../../../context/useLoading'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import UpIcon from '../../../../../assets/icons/Up.svg'
import ButtonsForm from './ButtonsForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const Buttons = ({ buttons, createHandler, updateHandler, deleteHandler }) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [buttonsItem, setButtonsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchItem, setSearchItem] = useState(null)
  const [listButtons, setListButtons] = useState(null)
  const { hideLoading, showLoading } = useLoading()

  useEffect(() => {
    if (searchItem) {
      const filteredButtons = buttons.filter(button => {
        return button.name?.toLowerCase().includes(searchItem.toLowerCase())
      })
      setListButtons(filteredButtons)
    } else {
      console.log(buttons)
      setListButtons(buttons)
    }
  }, [buttons, searchItem])

  const columns = [
    { title: 'buttonOrder', dataIndex: 'buttonOrder', width: '20%' },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setButtonsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setButtonsItem(item)
            }}
          >
            Удалить
          </Button>
          <div>
            <Link to={'../../sections'}>
              <Button style={{ marginLeft: '5%' }}>
                <Icon src={UpIcon} style={{ transform: 'rotate(-90deg)' }} />
              </Button>
            </Link>
            <Link to={`buttons/${item._id}/assessments`}>
              <Button style={{ marginLeft: '5%' }}>
                <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
              </Button>
            </Link>
          </div>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listButtons}
        columns={columns}
        rowKey={item => item._id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Текст"
              onChange={e => {
                setSearchItem(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая кнопка
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая секция"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <ButtonsForm form={form} />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
          }}
          onOk={() => {
            updateHandler(buttonsItem._id, formForEdit, setEditModal)
          }}
        >
          <ButtonsForm form={formForEdit} initialValues={buttonsItem} />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(buttonsItem._id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {buttonsItem.buttonName} ?</h3>
        </Modal>
      )}
    </>
  )
}

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Buttons
