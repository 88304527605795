import React, { useEffect } from 'react'
import { Form, Input } from 'antd'

const ButtonsForm = ({ form, initialValues }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        name="buttonOrder"
        label="buttonOrder"
        rules={[{ required: true, message: 'Пожалуйста, введите buttonOrder' }]}
        getValueFromEvent={event => {
          const value = event.target.value
          return value ? Number(value) : undefined
        }}
      >
        <Input type="number" />
      </Form.Item>
    </Form>
  )
}

export default ButtonsForm
