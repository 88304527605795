import React from 'react'

export const whatDidYouHearTable = [
  {
    title: 'Название',
    dataIndex: 'correctText',
    width: '15%'
  },
  {
    title: 'Дорожка',
    width: '5%',
    render: item => {
      let audioLink = item.audioUrl
      if (audioLink && !audioLink.startsWith('https://')) {
        audioLink = 'https://' + audioLink
      }
      return audioLink ? (
        <audio controls="controls">
          <source src={audioLink} type="audio/mpeg" />
        </audio>
      ) : null
    }
  },
  {
    title: 'Варианты',
    render: item => (
      <div>
        {item.variants.map(variant => (
          <div
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10
            }}
          >
            <span>
              <strong>Текст:</strong> {variant.text}
            </span>
            <span>
              <strong>Правильный индекс:</strong> {variant.correctIndex}
            </span>
          </div>
        ))}
      </div>
    )
  }
]
