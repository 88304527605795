import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import Assessments from './Assessments'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import { axiosKazlingoFormData } from '../../../utils/axiosKazlingo'
import { ASSESSMENT_T } from './enum'

const arrayFields = ['variants', 'column1', 'column2', 'correctMatches']

export const processAssessmentData = (formData, values) => {
  Object.keys(values).forEach(key => {
    if (arrayFields.includes(key) !== -1) {
      formData.append(key, values[key])
    }
  })

  if (values.type === ASSESSMENT_T.matchWords) {
    formData.set('column1', JSON.stringify(values.column1))
    formData.set('column2', JSON.stringify(values.column2))
    formData.set('correctMatches', JSON.stringify(values.correctMatches))
  }

  if (values.variants) {
    const variants = []
    values.variants.forEach(variant => {
      const { text, isCorrect, correctIndex, imageFile, audioFile } = variant
      switch (values.type) {
        case ASSESSMENT_T.chooseCorrectPicture:
          variants.push({ text, isCorrect })
          formData.append('variantsFileArray', imageFile || new Blob([]))
          break
        case ASSESSMENT_T.chooseCorrectTranslation:
        case ASSESSMENT_T.listenAudioChooseWord:
        case ASSESSMENT_T.translateWord:
        case ASSESSMENT_T.fillBlankSpace:
          variants.push({ text, isCorrect })
          break
        case ASSESSMENT_T.whatDidYouHear:
        case ASSESSMENT_T.translateSentence:
        case ASSESSMENT_T.listenAudioCompleteSentence:
          variants.push({ text, correctIndex })
        default:
          break
      }
    })
    formData.set('variants', JSON.stringify(variants))
  }
}

const AssessmentsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allAssessments, setAllAssessments] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const { data } = await axiosKazlingoFormData.get('/assessments')
        setAllAssessments(data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    fetchAssessments()
  }, [])

  const addAssessmentHandler = async (form, closeFunc) => {
    try {
      form.validateFields().then(async values => {
        form.resetFields()
        showLoading()
        const formData = new FormData()
        processAssessmentData(formData, values)
        const { data: newAssessment } = await axiosKazlingoFormData.post(
          '/assessments/' + values.type,
          formData
        )
        setAllAssessments([...allAssessments, newAssessment])
        toast.success('Задача добавлена')
        hideLoading()
      })
    } catch (error) {
      console.error('Ошибка при добавлении задачи:', error)
      toast.error('Ошибка при добавлении задачи')
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const updateAssessmentHandler = async (id, form, closeFunc) => {
    try {
      form.validateFields().then(async values => {
        showLoading()
        const formData = new FormData()
        processAssessmentData(formData, values)
        const { data: updatedAssessment } = await axiosKazlingoFormData.put(
          `/assessments/${values.type}/${id}`,
          formData
        )
        setAllAssessments(
          allAssessments.map(a => (a._id === id ? updatedAssessment : a))
        )
        toast.success('Задача обновлена')
        form.resetFields()
        hideLoading()
      })
    } catch (error) {
      console.error('Ошибка при обновлении задачи:', error)
      toast.error('Ошибка при обновлении задачи')
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteAssessmentHandler = async (id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoFormData.delete(`/assessments/${id}`)
      setAllAssessments(allAssessments.filter(a => a._id !== id))
      toast.success('Задача удалена')
      hideLoading()
    } catch (error) {
      console.error('Ошибка при удалении задачи:', error)
      toast.error('Ошибка при удалении задачи')
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке задачи</div>
  }

  return (
    <Assessments
      assessments={allAssessments}
      createHandler={addAssessmentHandler}
      updateHandler={updateAssessmentHandler}
      deleteHandler={deleteAssessmentHandler}
    />
  )
}

export default WithMainLayout(AssessmentsContainer)
