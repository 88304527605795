import React, { useEffect, useState, useCallback } from 'react'
import { Form, Select, InputNumber } from 'antd'
import debounce from 'lodash.debounce'
import { axiosKazlingoJson } from '../../../../../../utils/axiosKazlingo'
import { errorLog } from '../../../../../../utils/errorLog'

const { Option } = Select

const AssignAssessmentForm = ({
  form,
  initialValues,
  assessmentsGroup,
  groupIndex,
  assessmentsItem
}) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (assessmentsGroup) {
      form.setFieldsValue({ assessmentOrder: assessmentsGroup.length })
    }
  }, [assessmentsGroup])

  useEffect(() => {
    if (groupIndex) {
      form.setFieldsValue({ assessmentGroup: groupIndex })
    }
  }, [groupIndex])

  useEffect(() => {
    if (assessmentsItem) {
      form.setFieldsValue({ assessmentId: assessmentsItem._id })
      form.setFieldsValue({ assessment: assessmentsItem })
      setOptions([assessmentsItem])
    }
  }, [assessmentsItem])

  const fetchAssessments = async searchName => {
    setLoading(true)
    try {
      const response = await axiosKazlingoJson.get(
        `/assessments/name/${searchName}`
      )
      if (response.status === 200) {
        setOptions(response.data)
        console.log(response.data)
      }
    } catch (error) {
      errorLog(error)
    }
    setLoading(false)
  }

  const debouncedFetchAssessments = useCallback(
    debounce(fetchAssessments, 1000),
    []
  )

  const handleSearch = value => {
    if (value) {
      debouncedFetchAssessments(value)
    } else {
      setOptions([])
    }
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const handleSelectChange = value => {
    const selectedOption = options.find(option => option._id === value)
    form.setFieldsValue({ assessment: selectedOption })
  }

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item name="assessment" hidden />
      <Form.Item
        name="assessmentId"
        label="Выберите ассессмент"
        rules={[{ required: true, message: 'Пожалуйста, выберите ассессмент' }]}
      >
        <Select
          showSearch
          placeholder="Начните ввод для поиска"
          onSearch={handleSearch}
          loading={loading}
          filterOption={false}
          onChange={handleSelectChange}
          notFoundContent={loading ? 'Загрузка...' : 'Нет результатов'}
          defaultValue={assessmentsItem?._id ?? null}
        >
          {options.map(option => (
            <Option key={option._id} value={option._id}>
              Тип: {option.type} | Текст: {option.correctText}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="assessmentOrder"
        label="Порядок ассессмента"
        rules={[
          { required: true, message: 'Пожалуйста, укажите порядок ассессмента' }
        ]}
        initialValue={assessmentsGroup?.length ?? 0}
      >
        <InputNumber min={0} placeholder="Введите порядок" />
      </Form.Item>

      <Form.Item
        name="assessmentGroup"
        label="Группа ассессмента"
        rules={[
          { required: true, message: 'Пожалуйста, укажите группу ассессмента' }
        ]}
        initialValue={groupIndex ?? 0}
      >
        <InputNumber min={0} placeholder="Введите номер группы" />
      </Form.Item>
    </Form>
  )
}

export default AssignAssessmentForm
