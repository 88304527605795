export const ASSESSMENT_T = Object.freeze({
  chooseCorrectPicture: 'chooseCorrectPicture',
  chooseCorrectTranslation: 'chooseCorrectTranslation',
  whatDidYouHear: 'whatDidYouHear',
  matchWords: 'matchWords',
  translateSentence: 'translateSentence',
  translateWord: 'translateWord',
  listenAudioCompleteSentence: 'listenAudioCompleteSentence',
  listenAudioChooseWord: 'listenAudioChooseWord',
  fillBlankSpace: 'fillBlankSpace'
})

export const ASSESSMENT_T_LC = Object.freeze({
  chooseCorrectPicture: 'Выбери правильный рисунок',
  chooseCorrectTranslation: 'Выбери правильный перевод',
  whatDidYouHear: 'Что Вы услышали?',
  matchWords: 'Соедини слова',
  translateSentence: 'Переведи предложение',
  translateWord: 'Переведи слово',
  listenAudioCompleteSentence: 'Послушай и собери предложение',
  listenAudioChooseWord: 'Прослушай аудио и выбери слово',
  fillBlankSpace: 'Заполни пробел'
})
