import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import Upload from 'antd/lib/upload/Upload'
import { UploadOutlined } from '@ant-design/icons'

const WordsForm = ({ form, initialValues }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        name="text"
        label="text"
        rules={[{ required: true, message: 'Пожалуйста, введите text' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="translation"
        label="translation"
        rules={[{ required: true, message: 'Пожалуйста, введите translation' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="group"
        label="group"
        rules={[{ required: true, message: 'Укажите группу' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="imageFile"
        valuePropName="file"
        getValueFromEvent={e => e.file}
      >
        <Upload beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Выберите изображение</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="audioFile"
        valuePropName="file"
        getValueFromEvent={e => e.file}
      >
        <Upload beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Выберите аудио</Button>
        </Upload>
      </Form.Item>
    </Form>
  )
}

export default WordsForm
