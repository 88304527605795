import React from 'react'

export const matchWordsTable = [
  {
    title: 'Столбец 1',
    render: item => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {item.column1.map(val => (
          <span
            style={{
              marginBottom: 10,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1
            }}
          >
            {val.text}
          </span>
        ))}
      </div>
    ),
    width: '15%'
  },
  {
    title: 'Столбец 2',
    render: item => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {item.column2.map(val => (
          <span
            style={{
              marginBottom: 10,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1
            }}
          >
            {val.text}
          </span>
        ))}
      </div>
    ),
    width: '15%'
  },
  {
    title: 'Соединения',
    render: item => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {item.correctMatches.map(val => (
          <span
            style={{
              marginBottom: 10,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1
            }}
          >
            {val}
          </span>
        ))}
      </div>
    ),
    width: '15%'
  }
]
