import React, { useEffect } from 'react'
import { Button, Checkbox, Form, Input, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const ModulesForm = ({ form, initialValues }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        name="moduleName"
        label="moduleName"
        rules={[{ required: true, message: 'Пожалуйста, введите moduleName' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moduleTitle"
        label="moduleTitle"
        rules={[{ required: true, message: 'Пожалуйста, введите moduleTitle' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="moduleOrder"
        label="moduleOrder"
        rules={[{ required: true, message: 'Пожалуйста, введите moduleOrder' }]}
        getValueFromEvent={event => {
          const value = event.target.value
          return value ? Number(value) : undefined
        }}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        key="isVisible"
        label="Статус"
        name="isVisible"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option value={true}>Активный</Select.Option>
          <Select.Option value={false}>Скрытый</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={'moduleBannerImage'}
        label="moduleBannerImage"
        valuePropName="file"
        getValueFromEvent={e => e.file}
      >
        <Upload beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Выберите Баннер изображение</Button>
        </Upload>
      </Form.Item>
    </Form>
  )
}

export default ModulesForm
