import { toast } from 'react-toastify'

export const errorLog = error => {
  console.error('Ошибка:', error)
  const errorMessage =
    error.response && error.response.data.errors
      ? error.response.data.errors
          .map(error =>
            Object.entries(error)
              .map(([key, value]) => `${key}: ${value}`)
              .join('\n')
          )
          .join('\n')
      : 'Ошибка'
  toast.error(errorMessage)
}
