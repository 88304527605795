import React, { useEffect, useState } from 'react'
import { Table, Button, Form, Modal, Input, Select, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useLoading } from '../../../context/useLoading'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import UpIcon from '../../../assets/icons/Up.svg'
import ModulesForm from './ModulesForm'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

const Modules = ({ modules, createHandler, updateHandler, deleteHandler }) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [modulesItem, setModulesItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchItem, setSearchItem] = useState(null)
  const [listModules, setListModules] = useState(null)
  const { hideLoading, showLoading } = useLoading()

  useEffect(() => {
    if (searchItem) {
      const filteredModules = modules.filter(module => {
        return module.name?.toLowerCase().includes(searchItem.toLowerCase())
      })
      setListModules(filteredModules)
    } else {
      setListModules(modules)
    }
  }, [modules, searchItem])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.isVisible ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        />
      )
    },
    { title: 'moduleName', dataIndex: 'moduleName', width: '20%' },
    { title: 'moduleTitle', dataIndex: 'moduleTitle', width: '20%' },
    { title: 'moduleOrder', dataIndex: 'moduleOrder', width: '20%' },
    {
      title: 'moduleBannerImageUrl',
      render: module =>
        module?.moduleAssets?.moduleBannerImageUrl && (
          <Image
            shape="square"
            style={{ width: 150, height: 64 }}
            src={module.moduleAssets.moduleBannerImageUrl}
          />
        ),
      width: '20%'
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setModulesItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setModulesItem(item)
            }}
          >
            Удалить
          </Button>
          <Link to={`modules/${item._id}/sections`}>
            <Button style={{ marginLeft: '5%' }}>
              <Icon src={UpIcon} style={{ transform: 'rotate(90deg)' }} />
            </Button>
          </Link>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listModules}
        columns={columns}
        rowKey={item => item._id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Текст"
              onChange={e => {
                setSearchItem(e.target.value.toLowerCase())
              }}
            />
            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новый модуль
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новый модуль"
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <ModulesForm form={form} />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
          }}
          onOk={() => {
            updateHandler(modulesItem._id, formForEdit, setEditModal)
          }}
        >
          <ModulesForm form={formForEdit} initialValues={modulesItem} />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(modulesItem._id, setDeleteModal)}
        >
          <h3>Вы действительно хотите удалить {modulesItem.moduleName} ?</h3>
        </Modal>
      )}
    </>
  )
}

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default Modules
