import React, { useEffect } from 'react'
import { Button, Form, Input, Select, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const SectionsForm = ({ form, initialValues }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Form form={form} layout="vertical" initialValues={initialValues}>
      <Form.Item
        name="sectionName"
        label="sectionName"
        rules={[{ required: true, message: 'Пожалуйста, введите sectionName' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="sectionOrder"
        label="sectionOrder"
        rules={[
          { required: true, message: 'Пожалуйста, введите sectionOrder' }
        ]}
        getValueFromEvent={event => {
          const value = event.target.value
          return value ? Number(value) : undefined
        }}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        key="isVisible"
        label="Статус"
        name="isVisible"
        rules={[
          {
            required: true,
            message: `Пожалуйста, выберите статус`
          }
        ]}
      >
        <Select placeholder="Выберите" mode="single">
          <Select.Option value={true}>Активный</Select.Option>
          <Select.Option value={false}>Скрытый</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="sectionCityDescription"
        label="sectionCityDescription"
        rules={[{ required: true, message: 'Пожалуйста, введите sectionName' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'sectionBannerImage'}
        label="sectionBannerImage"
        valuePropName="file"
        getValueFromEvent={e => e.file}
      >
        <Upload beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Выберите Баннер изображение</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name={'sectionCityImage'}
        label="sectionCityImage"
        valuePropName="file"
        getValueFromEvent={e => e.file}
      >
        <Upload beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Выберите Город изображение</Button>
        </Upload>
      </Form.Item>
    </Form>
  )
}

export default SectionsForm
