import React from 'react'

export const chooseCorrectTranslationTable = [
  {
    title: 'Название',
    dataIndex: 'correctText',
    width: '15%'
  },
  {
    title: 'Варианты',
    render: item => (
      <div>
        {item.variants.map(variant => (
          <div
            style={{
              marginBottom: '10px',
              backgroundColor: variant.isCorrect
                ? 'rgba(173, 255, 47, 0.5)'
                : 'rgba(255, 0, 0, 0.5)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <span>
              <strong>Текст:</strong> {variant.text}
            </span>
          </div>
        ))}
      </div>
    )
  }
]
