import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLoading } from '../../../context/useLoading'
import WithMainLayout from '../../../hocs/withMainLayout'
import Loading from '../../shared/Loading'
import { axiosKazlingoFormData } from '../../../utils/axiosKazlingo'
import Words from './CommonWords'
import { errorLog } from '../../../utils/errorLog'

const processData = (formData, values) => {
  Object.keys(values).forEach(key => {
    formData.append(key, values[key])
  })
}

const WordsContainer = () => {
  const { showLoading, hideLoading } = useLoading()
  const [allWords, setAllWords] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const { data } = await axiosKazlingoFormData.get('/commonWords')
        setAllWords(data.data)
      } catch (error) {
        setError(error)
        toast.error('Ошибка при загрузке')
      } finally {
        setLoading(false)
      }
    }
    fetchWords()
  }, [])

  const addWordHandler = async (form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      processData(formData, values)
      const response = await axiosKazlingoFormData.post(
        '/commonWords',
        formData
      )
      if (response.status == 201) {
        setAllWords([...allWords, response.data])
        toast.success('Успешно добавлено')
      }
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const updateWordHandler = async (_id, form, closeFunc) => {
    try {
      const values = await form.validateFields()
      form.resetFields()
      showLoading()
      const formData = new FormData()
      processData(formData, values)
      const response = await axiosKazlingoFormData.put(
        `/commonWords/details/${_id}`,
        formData
      )
      if (response.status == 201) {
        setAllWords(allWords.map(a => (a._id === _id ? response.data : a)))
        toast.success('Успешно обновлено')
      }
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteWordHandler = async (_id, closeFunc) => {
    try {
      showLoading()
      await axiosKazlingoFormData.delete(`/commonWords/details/${_id}`)
      setAllWords(allWords.filter(a => a._id !== _id))
      toast.success('Успешно удалено')
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке</div>
  }

  return (
    <Words
      words={allWords}
      createHandler={addWordHandler}
      updateHandler={updateWordHandler}
      deleteHandler={deleteWordHandler}
    />
  )
}

export default WithMainLayout(WordsContainer)
