import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Assessments from './Assessments'
import WithMainLayout from '../../../../../../hocs/withMainLayout'
import Loading from '../../../../../shared/Loading'
import {
  axiosKazlingoFormData,
  axiosKazlingoJson
} from '../../../../../../utils/axiosKazlingo'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useLoading } from '../../../../../../context/useLoading'
import { processAssessmentData } from '../../../../assessments/Assessments.container'
import { errorLog } from '../../../../../../utils/errorLog'
import { Form } from 'antd'

const AssessmentsContainer = () => {
  const { moduleId, sectionId, buttonId } = useParams()
  const [allAssessmentsGroup, setAllAssessmentsGroup] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { showLoading, hideLoading } = useLoading()
  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const { data } = await axiosKazlingoFormData.get(
          `/roadmaps/${moduleId}/sections/${sectionId}/buttons/${buttonId}`
        )
        setAllAssessmentsGroup(data.assessments)
      } catch (error) {
        errorLog(error)
      } finally {
        setLoading(false)
      }
    }
    fetchAssessments()
  }, [])

  const createAssessmentHandler = async (
    form,
    setItem,
    closeFunc,
    nextModal
  ) => {
    try {
      const values = await form.validateFields()
      await form.resetFields()
      showLoading()
      const formData = new FormData()
      processAssessmentData(formData, values)
      const response = await axiosKazlingoFormData.post(
        '/assessments/' + values.type,
        formData
      )
      toast.success('Успешно создано')
      setItem(response.data)
      nextModal(true)
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const assignAssessmentHandler = async (form, closeFunc = () => {}) => {
    try {
      const values = await form.validateFields()
      const { assessment, assessmentOrder, assessmentGroup } = values
      form.resetFields()
      showLoading()
      await axiosKazlingoJson.post(
        `/roadmaps/${moduleId}/sections/${sectionId}/buttons/${buttonId}/assessments`,
        { assessmentId: assessment._id, assessmentOrder, assessmentGroup }
      )
      setAllAssessmentsGroup(prev => {
        const updated = [...prev]

        if (updated[assessmentGroup]) {
          updated[assessmentGroup] = [
            ...updated[assessmentGroup],
            { assessment, assessmentOrder }
          ]
        } else {
          updated[assessmentGroup] = [{ assessment, assessmentOrder }]
        }

        return updated
      })

      toast.success('Добавление успешно')
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const unAssignAssessmentHandler = async (
    id,
    groupIndex,
    closeFunc = () => {}
  ) => {
    try {
      showLoading()
      await axiosKazlingoFormData.delete(
        `/roadmaps/${moduleId}/sections/${sectionId}/buttons/${buttonId}/assessments/${id}?assessmentGroup=${groupIndex}`
      )
      setAllAssessmentsGroup(prev => [
        ...prev.slice(0, groupIndex),
        prev[groupIndex].filter(a => a._id !== id),
        ...prev.slice(groupIndex + 1)
      ])
      toast.success('Удаление успешно')
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const editAssessmentHandler = async (assessmentsItem, form, closeFunc) => {
    try {
      const values = await form.validateFields()
      const formData = new FormData()
      form.resetFields()
      showLoading()
      processAssessmentData(formData, values)
      await axiosKazlingoFormData.put(
        `/assessments/${values.type}/${assessmentsItem.assessment._id}`,
        formData
      )
      toast.success('Изменение успешно')
    } catch (error) {
      errorLog(error)
    } finally {
      hideLoading()
      closeFunc(false)
    }
  }

  const deleteGroupHandler = async (index, closeFunc) => {
    try {
      await axiosKazlingoJson.delete(
        `/roadmaps/${moduleId}/sections/${sectionId}/buttons/${buttonId}/assessmentGroups/${index}`
      )
      setAllAssessmentsGroup(prev => {
        const newArray = [...prev]
        newArray.splice(index, 1)
        return newArray
      })
      closeFunc()
    } catch (error) {
      console.log('error')
    }
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>Ошибка при загрузке задачи</div>
  }

  return (
    <Assessments
      allAssessmentsGroup={allAssessmentsGroup}
      createGroupHandler={() => {
        toast.error('Такого функционала нет')
      }}
      deleteGroupHandler={deleteGroupHandler}
      createAssessmentHandler={createAssessmentHandler}
      assignAssessmentHandler={assignAssessmentHandler}
      unAssignAssessmentHandler={unAssignAssessmentHandler}
      editAssessmentHandler={editAssessmentHandler}
    />
  )
}

export default WithMainLayout(AssessmentsContainer)
