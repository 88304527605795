import React, { useEffect, useRef, useState } from 'react'
import { Table, Button, Form, Modal, message, Input, Image } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ProverbsCollectionsForm from './ProverbsCollectionsForm'
import { useLoading } from '../../../context/useLoading'

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  width: 256px;
`

const ProverbsCollections = ({
  proverbsCollections: proverbsCollections,
  proverbs: proverbs,
  createHandler,
  updateHandler,
  deleteHandler
}) => {
  const [form] = Form.useForm()
  const [formForEdit] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [proverbsCollectionsItem, setProverbsCollectionsItem] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [searchProverbsCollection, setSearchProverbsCollection] = useState(null)
  const [listProverbs, setListProverbs] = useState(null)
  const { hideLoading } = useLoading()

  useEffect(() => {
    if (searchProverbsCollection) {
      const searchArray = proverbsCollections.filter(proverbsCollection => {
        return (
          proverbsCollection.titleRus
            ?.toLowerCase()
            .includes(searchProverbsCollection) ||
          proverbsCollection.titleKaz
            ?.toLowerCase()
            .includes(searchProverbsCollection)
        )
      })
      setListProverbs(searchArray)
    } else {
      setListProverbs(proverbsCollections)
    }
  }, [proverbsCollections, searchProverbsCollection])

  const columns = [
    {
      title: '',
      width: '1%',
      render: item => (
        <div
          style={{
            background: item.status === 'active' ? '#44FB82' : '#D4D4D4',
            height: '60px',
            width: '20px',
            margin: '-10px'
          }}
        ></div>
      )
    },
    {
      title: 'Название (на русском)',
      dataIndex: 'titleRus',
      width: '15%'
    },
    {
      title: 'Название (на казахском)',
      dataIndex: 'titleKaz',
      width: '15%'
    },
    {
      title: 'Вертикальная картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.verticalImageUrl} />
          </>
        )
      }
    },
    {
      title: 'Горизонтальная картинка',
      width: '10%',
      render: item => {
        return (
          <>
            <Image shape="square" size={64} src={item.horizontalImageUrl} />
          </>
        )
      }
    },
    {
      title: 'Действие',
      width: '20%',
      render: item => (
        <>
          <Button
            type="link"
            onClick={() => {
              formForEdit.resetFields()
              setEditModal(true)
              setProverbsCollectionsItem(item)
            }}
          >
            Редактировать
          </Button>
          <Button
            danger
            type="link"
            onClick={() => {
              setDeleteModal(true)
              setProverbsCollectionsItem(item)
            }}
          >
            Удалить
          </Button>
        </>
      )
    }
  ]

  return (
    <>
      <Table
        dataSource={listProverbs}
        columns={columns}
        rowKey={item => item.id}
        title={() => (
          <StyledHeaderContainer>
            <StyledInput
              prefix={<SearchOutlined />}
              placeholder="Название"
              onChange={e => {
                setSearchProverbsCollection(e.target.value.toLowerCase())
              }}
            />

            <Button type="primary" onClick={() => setModalVisible(true)}>
              <PlusOutlined /> Новая коллекция
            </Button>
          </StyledHeaderContainer>
        )}
      />
      <Modal
        open={modalVisible}
        title="Новая коллекция "
        okText="Создать"
        cancelText="Отмена"
        onCancel={() => {
          form.resetFields()
          setModalVisible(false)
        }}
        onOk={() => {
          createHandler(form, setModalVisible)
        }}
      >
        <ProverbsCollectionsForm form={form} proverbs={proverbs} />
      </Modal>
      {editModal && (
        <Modal
          open={editModal}
          onCancel={() => {
            hideLoading()
            setEditModal(false)
          }}
          onOk={() => {
            updateHandler(formForEdit, proverbsCollectionsItem.id, setEditModal)
          }}
        >
          <ProverbsCollectionsForm
            form={formForEdit}
            titleRus={proverbsCollectionsItem.titleRus}
            titleKaz={proverbsCollectionsItem.titleKaz}
            status={proverbsCollectionsItem.status}
            proverbs={proverbs}
            selectedProverbs={proverbsCollectionsItem.proverbs}
          />
        </Modal>
      )}
      {deleteModal && (
        <Modal
          open={deleteModal}
          onCancel={() => {
            setDeleteModal(false)
          }}
          onOk={() => deleteHandler(proverbsCollectionsItem.id, setDeleteModal)}
        >
          <h3>
            Вы действительно хотите удалить {proverbsCollectionsItem.titleRus} ?
          </h3>
        </Modal>
      )}
    </>
  )
}

ProverbsCollections.propTypes = {
  proverbsCollections: PropTypes.arrayOf(PropTypes.object).isRequired,
  proverbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  createHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired
}

export default ProverbsCollections
